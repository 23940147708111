import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/vant.js";
import "./assets/style/reset.css";
import "./assets/style/border.css";
import VueClipboard from "vue-clipboard2";
import { Image as VanImage } from "vant";
import VideoPlayer from "vue-video-player";
import { Tag } from 'vant';

Vue.use(Tag);
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
Vue.use(VideoPlayer);
Vue.use(VanImage);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import "amfe-flexible";
import store from "./store";
Vue.config.productionTip = false;
import moment from "moment";
import fastClick from "fastclick";
fastClick.attach(document.body);
Vue.filter("dateFormatMonth", (val) => {
  return moment(val).format("YYYY年MM月DD日 ");
});
Vue.filter("dateFormatMonths", (val) => {
  return moment(val).format("YYYY-MM-DD ");
});

Vue.filter("dateFormatMonthsText", (val) => {
  return moment(val).format("YYYY年M月D日");
});
Vue.filter("formatKoreanNumber", (number) => {
  // 确保输入是数字
  if (isNaN(number)) return "-";
  // 将数字乘以 100 并保留两位小数
  let formattedNumber = (number * 100).toFixed(2);
  // 将数字格式化为韩式数字
  let koreanNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // 返回结果
  return koreanNumber;
});

Vue.filter("formatKorean", (number) => {
  // 确保输入是数字
  if (isNaN(number)) return "-";
  // 将数字乘以 100 并保留两位小数
  let formattedNumber = number.toFixed(2);
  // 将数字格式化为韩式数字
  let koreanNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // 返回结果
  return koreanNumber;
});
Vue.prototype.HOST = "/api";
(Vue.prototype.formatCurrency = function (value) {
  if (!value && value !== 0) return "-";
  let intPart = Number(value) | 0; //只获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分只要取到三位就截取
  let floatPart = ".00"; //预定义小数部分
  let value2Array = value.toString().split(".");
  //=2表示数据有小数位
  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString(); //拿到小数部分
    if (floatPart.length == 1) {
      //补0,实际上用不着
      return intPartFormat + "." + floatPart + "0";
    } else {
      return intPartFormat + "." + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
}),
  router.beforeEach((to, from, next) => {
    switch (to.path) {
      case "/":
        document.title = "商用车大数据平台";
        break;
      case "/verification":
      case "/message":
        document.title = "商用车评估师管理平台";
        break;
      case "/report":
      case "/newReport":
        document.title = "商用车鉴定评估报告预览";
        break;
      case "/mallReport":
        document.title = "二手商用车检测报告";
        break;
      case "/carMessage":
        document.title = "车辆信息";
        break;
      case "/eva":
      case '/defaultEva':
        document.title = "岗位技能证书";
        break;
      case "/newReportMsg":
        document.title = "车辆技术状况";
        break;
      case "/certificateReport":
        document.title = "商用车认证资料";
        break;
      case "/reportInfo":
        document.title = "基本信息";
        break;
      case "/reportMessage":
        document.title = "详细报告";
        break;
      case "/impairment":
        document.title = "实体性减值项";
        break;
      case "/swiper":
      case "/imgList":
        document.title = "全部图片";
        break;
      case "/quickValuation/Home":
      case "/quickValuation/secondarySubmit":
      case "/valuation/home/v2":
      case "/quickValuation/v2/secondarySubmit":
        document.title = "快速估值";
        break;
      case "/quickValuation/details":
      case "/quickValuation/v2/details":
        document.title = "估值结果";
        break;
      case "/quickValuation/v2/help":
        document.title = "引导页";
        break;
      case "/managerInfo":
      case "/success":
        document.title = "商用车经理人指数CVMI";
        break;
      default:
        document.title = "会务统计";
        break;
    }
    next();
  });
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
